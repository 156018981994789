import "styles/sections/credibility/CredibilitySection.scss";
import "styles/sections/credibility/CredibilityStats.scss";
import {
  MutableRefObject,
  memo,
  useRef,
  useState,
  useEffect,
  useContext,
} from "react";
import {
  HeadingDecoration,
  Section,
  SectionHeadline,
  NonBreakableSpaces,
  StrongText,
} from "components";
import { LangProp, Section as SectionType } from "types";
import { credibility } from "translations";
import CredibilityBadges, { SvgImage } from "./CredibilityBadges";
import {
  Deloitte,
  FinancialTimes,
  Kantar,
  Mmp,
  OrigamiLilies,
  Stats,
  StatsMobile,
} from "images-react";
import CredibilityAwards from "./CredibilityAwards";
import { useParallax } from "react-scroll-parallax";
import { useOnScreen, useScreenResize } from "hooks";
import clsx from "clsx";
import { DeviceContext } from "components/App";

const luckandchampions_w67 = "images-react/kenji/luckandchampions_w67.png";
const luckandchampions_w134 = "images-react/kenji/luckandchampions_w134.png";

const deloitte01_w674png = "images-react/credibility/deloitte-01_w674.png";
const deloitte01_w1348png = "images-react/credibility/deloitte-01_w1348.png";
const deloitte02_w674png = "images-react/credibility/deloitte-02_w674.png";
const deloitte02_w1348png = "images-react/credibility/deloitte-02_w1348.png";
const deloitte01_w674webp = "images-react/credibility/deloitte-01_w674.webp";
const deloitte01_w1348webp = "images-react/credibility/deloitte-01_w1348.webp";
const deloitte02_w674webp = "images-react/credibility/deloitte-02_w674.webp";
const deloitte02_w1348webp = "images-react/credibility/deloitte-02_w1348.webp";

const mindsAndRosesUrl = "images-react/logos/minds-and-roses.png";

const awardGiver: { [key: string]: SvgImage } = {
  mindsAndRoses: {
    title: "Minds & Roses",
    imageUrl: mindsAndRosesUrl,
  },
  kantar: {
    Svg: Kantar,
    title: "Kantar",
  },
  mmp: {
    Svg: Mmp,
    title: "Media Marketing Polska",
  },
  deloitte: {
    Svg: Deloitte,
    title: "Deloitte",
  },
  financialTimes: {
    Svg: FinancialTimes,
    title: "Financial Times",
  },
};

const CredibilitySection = memo(function CredibilitySection({
  lang,
  label,
  refer,
}: Props) {
  const isMobileOrTablet = useContext(DeviceContext);
  const isMobile = useScreenResize(688);

  const { headline, paragraphs, imageAlternatives, awards, awardsLabel } =
    credibility[lang];

  const { ref: image1 } = useParallax<HTMLImageElement>({
    translateX: ["-200px", "0px", "easeOutQuint"],
    opacity: [0, 1, "easeOutQuint"],
    rootMargin: { top: 0, right: 0, bottom: 0, left: 0 },
    disabled: isMobileOrTablet,
  });
  const { ref: image2 } = useParallax<HTMLImageElement>({
    translateX: ["200px", "0px", "easeOutQuint"],
    opacity: [0, 1, "easeOutQuint"],
    rootMargin: { top: 0, right: 0, bottom: 0, left: 0 },
    disabled: isMobileOrTablet,
  });

  const statsRef = useRef(null);
  const isStatsVisible = useOnScreen(statsRef, 0);
  const [isStatsViewed, setIsStatsViewed] = useState(false);

  useEffect(() => {
    if (isStatsVisible) setIsStatsViewed(true);
  }, [isStatsVisible]);

  return (
    <Section className="credibility" label={label} refer={refer}>
      <SectionHeadline
        className="credibility__primary-headline"
        decoration={
          <HeadingDecoration
            className="credibility__decoration"
            src={[
              { size: 67, src: luckandchampions_w67 },
              { size: 134, src: luckandchampions_w134 },
            ]}
          />
        }
      >
        {headline}
      </SectionHeadline>

      <CredibilityBadges images={[awardGiver.mindsAndRoses, awardGiver.mmp]} />

      {isMobile ? (
        <p
          className="credibility__paragraph credibility__paragraph--strong"
          aria-hidden="true"
        >
          <NonBreakableSpaces>{paragraphs[0]}</NonBreakableSpaces>
        </p>
      ) : null}

      <CredibilityAwards images={awards} label={awardsLabel} />
      <div className="credibility__texts">
        <p
          className={clsx(
            "credibility__paragraph credibility__paragraph--strong",
            isMobile && "visually-hidden"
          )}
        >
          <NonBreakableSpaces>{paragraphs[0]}</NonBreakableSpaces>
        </p>
        <p className="credibility__paragraph credibility__paragraph--weak">
          <StrongText>{paragraphs[1]}</StrongText>
        </p>
      </div>

      <div
        className={clsx(
          "credibility__stats-container",
          (isStatsVisible || isStatsViewed) && "animate"
        )}
        ref={statsRef}
      >
        {isMobile ? (
          <StatsMobile className="credibility__stats-image credibility__stats-image--mobile" />
        ) : (
          <Stats className="credibility__stats-image" />
        )}
      </div>

      <section>
        <OrigamiLilies
          className="credibility__origami"
          role="presentation"
          aria-hidden="true"
        />
        <h4 className="credibility__headline">
          <NonBreakableSpaces>{paragraphs[2]}</NonBreakableSpaces>
        </h4>

        <CredibilityBadges
          images={[awardGiver.deloitte, awardGiver.financialTimes]}
        />

        <div className="credibility__gallery">
          <div className="credibility__image-container credibility__image-container--1">
            <picture>
              <source
                type="image/webp"
                srcSet={`${deloitte01_w674webp} 674w, ${deloitte01_w1348webp} 1348w`}
                sizes="674px"
              />
              <img
                src={deloitte01_w1348png}
                srcSet={`${deloitte01_w674png} 674w, ${deloitte01_w1348png} 1348w`}
                sizes="674px"
                className="credibility__image"
                alt={imageAlternatives[0]}
                ref={image1}
              />
            </picture>
          </div>
          <div className="credibility__image-container credibility__image-container--2">
            <picture>
              <source
                type="image/webp"
                srcSet={`${deloitte02_w674webp} 674w, ${deloitte02_w1348webp} 1348w`}
                sizes="674px"
              />
              <img
                src={deloitte02_w1348png}
                srcSet={`${deloitte02_w674png} 674w, ${deloitte02_w1348png} 1348w`}
                sizes="674px"
                className="credibility__image"
                alt={imageAlternatives[1]}
                ref={image2}
              />
            </picture>
          </div>
        </div>
      </section>
    </Section>
  );
});

interface Props extends LangProp {
  label: SectionType;
  refer: MutableRefObject<HTMLHeadingElement | null>;
}

export default CredibilitySection;
