import clsx from "clsx";
import { useOnScreen } from "hooks";
import { useEffect, useRef, useState } from "react";
import "styles/sections/credibility/CredibilityBadges.scss";

interface Props {
  images: [SvgImage, SvgImage];
}

export interface SvgImage {
  Svg?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  title: string;
  imageUrl?: string;
}

function CredibilityBadges({ images }: Props) {
  const sectionRef = useRef<HTMLElement>(null);
  const [isSectionViewed, setIsSectionViewed] = useState(false);
  const isSectionVisible = useOnScreen(sectionRef, 1);

  useEffect(() => {
    if (isSectionVisible) setIsSectionViewed(true);
  }, [isSectionVisible]);

  return (
    <section
      className={clsx(
        "credibility-badges",
        (isSectionVisible || isSectionViewed) && "animate"
      )}
      ref={sectionRef}
    >
      {images.map(({ Svg, title, imageUrl }) => (
        <div className="credibility-badges__badge-container" key={title}>
          <svg
            className="credibility-badges__circle-container"
            height="100%"
            width="100%"
          >
            <circle
              className="credibility-badges__circle"
              cx="50%"
              cy="50%"
              r="49.5%"
              stroke="#b9b1b4"
              strokeWidth="1"
              fillOpacity="0"
            />
          </svg>
          {Svg ? (
            <Svg
              className="credibility-badges__badge-image"
              role="img"
              title={title}
            />
          ) : (
            <img
              className="credibility-badges__badge-image"
              src={imageUrl}
              alt={title}
            />
          )}
        </div>
      ))}
    </section>
  );
}

export default CredibilityBadges;
